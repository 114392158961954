export default {
    namespaced: true,
    state: {
        has_unsupported_version: false,
        dismissed_unsupported_message: false,
    },
    mutations: {
        setHasUnsupportedVersion(state, has_unsupported_version) {
            state.has_unsupported_version = has_unsupported_version;
        },
        setHasDismissedUssuportedMessage(state, dismissed_unsupported_message) {
            state.dismissed_unsupported_message = dismissed_unsupported_message;
        },
    },
    getters: {
        shouldDisplayMessage: (state) =>
            !state.dismissed_unsupported_message &&
            state.has_unsupported_version
    },
};
