<template>
    <div class="modal-popup" v-if="!isDismissed && (canInstallPwa || isApple)">
        <div class="modal-popup-wrapper">
            <div class="modal-popup-header">
                <h3 class="modal-popup-title">
                    {{ $t("Hi") }}
                </h3>
                <button class="modal-popup-close" @click="closeInstallPopup">
                    <iconClose />
                </button>
            </div>
            <div class="modal-popup-content">
                <p v-if="isApple">
                    {{
                        $t(
                            "This App functions even better when you install it"
                        )
                    }}.
                    {{ $t("Press") }}
                    <img src="@/assets/icons/icon-share.svg" />
                    {{ $t("and choose") }}
                    "<img src="@/assets/icons/icon-plus-square.svg" />
                    {{ $t("Add to home screen") }}"
                </p>
                <template v-if="!isApple && canInstallPwa">
                    <p>
                        {{
                            $t(
                                "This App functions even better when you install it. Install the Project Gezond App on the home screen."
                            )
                        }}
                    </p>
                    <button class="btn -theme -lg" @click="promptPWA()">
                        {{ $t("Install Project Gezond as app") }}
                    </button>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import PWA from "@/lib/PWA";
import iconClose from "@/assets/icons/icon-close.svg?inline";

export default {
    name: "InstallPopup",

    components: {
        iconClose,
    },
    data() {
        return {
            isApple: null,
            isDismissed: true,
            canInstallPwa: false,
        };
    },
    async mounted() {
        this.isApple = PWA.isApple();

        if (localStorage.getItem("install-popup-dismissed") !== "1") {
            this.isDismissed = false;
        }

        setInterval(() => {
            this.canInstallPwa = PWA.HasInstallAvailable();
        }, 1000);
    },
    methods: {
        promptPWA() {
            PWA.PromptInstall();
        },
        closeInstallPopup() {
            localStorage.setItem("install-popup-dismissed", "1");
            this.isDismissed = true;
        }
    }
};
</script>

<style lang="scss">
@import "index";
</style>
