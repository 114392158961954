<template>
    <modal
        :title="$t('Welcome {user}!', { user: getUser.getFirstName() })"
        class="introduction-modal"
        v-if="isIntroduction && !modal_personal_info"
    >
        <p>
            {{
                $t(
                    "Let's get started with your personal information. With this we can calculate your calorie needs, and you can easily track your progress. Filling this is only takes one minute."
                )
            }}
        </p>

        <div class="flex justify-center mt-10">
            <button class="btn -accent -lg" @click="modal_personal_info = true">
                <span>{{ $t("Fill in details") }}</span>
            </button>

            <button class="btn" @click="skipIntroduction">
                <span>{{ $t("Skip") }}</span>
            </button>
        </div>
    </modal>

    <personal-info-modal
        v-if="modal_personal_info"
        @close="modal_personal_info = false"
    />
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import UsersApi from "@/lib/api/Users";
import User from "@/models/User";
import Modal from "@/components/Modal";
import PersonalInfoModal from "@/views/Account/SettingsModal/PersonalInfoModal";

export default {
    components: {
        Modal,
        PersonalInfoModal,
    },
    data() {
        return {
            modal_personal_info: false,
        };
    },
    methods: {
        ...mapMutations("user", ["setUser"]),
        async skipIntroduction() {
            const resp = await UsersApi.UpdateUser(
                { introduction: false },
                this.getUser.getId()
            );

            this.setUser(new User(resp));
            return;
        },
    },
    computed: {
        ...mapGetters("user", ["getUser"]),
        isIntroduction() {
            const setting = this.getUser.getCustomerSetting("introduction");

            if (!setting) {
                return true;
            }

            return setting.value;
        },
    }
};
</script>

<style lang="scss">
@import ".";
</style>
