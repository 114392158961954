import { createRouter, createWebHashHistory } from "vue-router";
import Store from "@/store";

const routes = [
    {
        path: "/",
        name: "AppLayout",
        component: () =>
            import(
                /* webpackChunkName: "dashboard" */ "@/layouts/AppLayout.vue"
            ),
        children: [
            {
                path: "/",
                name: "Dashboard",
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "@/views/Dashboard"
                    ),
            },
            {
                // TODO: TEMP
                path: "/temp-components",
                name: "TempComponents",
                component: () =>
                    import(
                        /* webpackChunkName: "temp" */ "@/views/TempComponents.vue"
                    ),
            },
            {
                path: "/week-menu/:weekquery?",
                name: "WeekMenu",
                component: () =>
                    import(
                        /* webpackChunkName: "recipes" */ "@/views/WeekMenu"
                    ),
            },
            {
                path: "/recepten",
                name: "Recipes",
                component: () =>
                    import(/* webpackChunkName: "recipes" */ "@/views/Recipes"),
            },
            {
                path: "/recepten/:slug",
                name: "Recipe",
                component: () =>
                    import(/* webpackChunkName: "recipes" */ "@/views/Recipe"),
                props: true
            },
            {
                path: "/dag-menu/:slug",
                name: "DayMenu",
                component: () =>
                    import(/* webpackChunkName: "recipes" */ "@/views/DayMenu"),
            },
            {
                // ! Redirect day-menu to dag-menu
                path: "/day-menu/:slug",
                redirect: (to) => {
                    return { path: `/dag-menu/${to.params.slug}` };
                },
            },
            {
                path: "/my-pg",
                name: "Account",
                component: () =>
                    import(/* webpackChunkName: "account" */ "@/views/Account"),
            },
            {
                path: "/cms-menu",
                name: "Meer",
                component: () => import("@/views/CMSMenu"),
            },
            {
                path: "/pagina/bmi",
                name: "PageBmi",
                component: () =>
                    import(
                        /* webpackChunkName: "cms" */ "@/views/CMSPage/Pages/BMI"
                    ),
            },
            {
                path: "/pagina/kcal",
                name: "PageKcal",
                component: () =>
                    import(
                        /* webpackChunkName: "cms" */ "@/views/CMSPage/Pages/Kcal"
                    ),
            },
            {
                path: "/pagina/contact",
                name: "PageContact",
                component: () =>
                    import(
                        /* webpackChunkName: "cms" */ "@/views/CMSPage/Pages/Contact"
                    ),
            },
            {
                path: "/pagina/:slug(.*)",
                name: "CMSPage",
                component: () =>
                    import(/* webpackChunkName: "cms" */ "@/views/CMSPage"),
            },
        ],
    },
    {
        path: "/",
        name: "LoginLayout",
        component: () =>
            import(
                /* webpackChunkName: "dashboard" */ "@/layouts/LoginLayout.vue"
            ),
        children: [
            {
                path: "/welkom/:query(.*)?",
                name: "welkom",
                component: () =>
                    import(/* webpackChunkName: "start" */ "@/views/Login"),
            },
        ],
    },
    {
        path: "/:pathMatch(.*)*",
        name: "PageNotFound",
        component: () =>
            import(
                /* webpackChunkName: "cms" */ "@/views/CMSPage/Pages/PageNotFound"
            ),
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

// * Scroll to top of page when changing route
router.beforeEach((to, from) => {
    if (from.name === "Recipes") {
        const scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;
        Store.commit("algolia/setScrollPosition", scrollPosition);
    }

    if (to.name === "Recipe") {
        window.scrollTo(0, 0);
    }

    return true;
});

export default router;
