import DataObject from "./DataObject";
import DateTime from "@/lib/misc/DateTime";

export default class WeightLog extends DataObject {
    constructor(data) {
        super(data);
    }

    getFormattedDate(day = false) {
        let date = this.getDay();

        return DateTime.ConvertDateToHumanReadable(
            DateTime.Date(date),
            day
        );
    }

    getFormattedWeight() {
        return this.getWeight()
            .toFixed(1)
            .replace(".", ",") + " KG";
    }
}
