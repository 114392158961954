import { createStore } from "vuex";

import toasts from "./modules/toasts";
import updater from "./modules/updater";
import algolia from "./modules/algolia";
import user from "./modules/user";
import plannedDayMenus from "./modules/plannedDayMenus";
import favorites from "./modules/favorites";
import loader from "./modules/loader";
import unsupportversion from "./modules/unsupportedVersion";

export default createStore({
    modules: {
        toasts,
        updater,
        algolia,
        user,
        plannedDayMenus,
        favorites,
        loader,
        unsupportversion,
    },
});
