export default {
    namespaced: true,
    state: {
        plannedDayMenus: null,
        selectedDates: [],
    },
    getters: {
        getSelectedDates: state => state.selectedDates,
    },
    mutations: {
        setPlannedDayMenus(state, plannedDayMenus) {
            state.plannedDayMenus = plannedDayMenus;
        },
    },
    actions: {
        /**
         * Adds planned day menu to the month its was planned on.
         * If the month does not exist this method will create it
         *
         * @param dispatch
         * @param state
         * @param {{date: Date}} day
         * @param {PlannedDayMenu} plannedDayMenu
         */
        addPlannedDayMenuToMonth({state, dispatch}, {day, plannedDayMenu}) {
            let original = state.plannedDayMenus || [];

            // Returns -1 if false
            const yearMonthIndex = original.findIndex(plannedChuck =>
                plannedChuck.date.getMonth() === day.date.getMonth()
                && plannedChuck.date.getFullYear() === day.date.getFullYear(),
            );

            if (yearMonthIndex >= 0) {
                dispatch(
                    "addPlannedDayMenuToList",
                    {yearMonthIndex, plannedDayMenu},
                );
                return;
            }

            const month = day.date.getMonth();
            const year = day.date.getFullYear();

            // Create new item
            original.push({
                date: new Date(year, month, 1, 0, 0, 0, 0),
                plannedDayMenus: [plannedDayMenu],
            });

            // Resort array
            original = original.sort(
                (a, b) => a.date.getTime() - b.date.getTime(),
            );

            state.plannedDayMenus = original;
        },
        addPlannedDayMenuToList(
            {state},
            {yearMonthIndex, plannedDayMenu},
        ) {
            const original = state.plannedDayMenus;

            // Returns -1 if false
            const index = original[yearMonthIndex].plannedDayMenus.findIndex(
                item =>
                    item.getPlannedAt().getTime() ===
                    plannedDayMenu.getPlannedAt().getTime(),
            );

            if (index >= 0) {
                // Override existing item which was replaced
                original[yearMonthIndex].plannedDayMenus[index] = plannedDayMenu;
                return;
            }

            // Add to list
            original[yearMonthIndex].plannedDayMenus.push(plannedDayMenu);

            // Resort array
            original[yearMonthIndex].plannedDayMenus.sort(
                (a, b) =>
                    a.getPlannedAt().getTime() - b.getPlannedAt().getTime(),
            );

            state.plannedDayMenus = original;
        },
        removePlannedDayMenusFromList({state}, {plannedDayMenu}) {
            const original = state.plannedDayMenus;
            const plannedDate = plannedDayMenu.getPlannedAt();

            const yearMonthIndex = original.findIndex(plannedChuck =>
                plannedChuck.date.getMonth() === plannedDate.getMonth() &&
                plannedChuck.date.getFullYear() === plannedDate.getFullYear(),
            );

            if (yearMonthIndex === -1) {
                // Panic, this should not be possible.
                console.error(
                    "removePlannedDayMenusFromList was called " +
                    "for a day menu that does not exist in the list." +
                    "This action was halted to prevent errors",
                );
                return;
            }

            const index = original[yearMonthIndex].plannedDayMenus.findIndex(
                item => item.getPlannedAt().getTime() === plannedDate.getTime(),
            );

            original[yearMonthIndex].plannedDayMenus.splice(index, 1);

            if (original[yearMonthIndex].plannedDayMenus.length === 0) {
                original.splice(yearMonthIndex, 1);
            }

            this.plannedDayMenus = original;
        },
        setSelectDate({state}) {
            let original = [];

            for (let monthYear of state.plannedDayMenus) {
                original = original.concat(
                    monthYear.plannedDayMenus.map(
                        data => data.getPlannedAt().getTime()),
                );
            }

            state.selectedDates = original;
        },
    },
};
