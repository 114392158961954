export default {
    namespaced: true,
    state: {
        postpone_duration: 1 * 60 * 60 * 1000, // 1 hour

        is_out_of_date: false,
        postponed_until: null,
        server_version: null
    },
    getters: {
        shouldDisplayUpdater: state =>
            !!state.is_out_of_date && state.postponed_until < Date.now(),

        readableServerVersion: state =>
            state.server_version
                ? state.server_version.substring(state.server_version.length - 7)
                : "unknown"
    },
    mutations: {
        setServerVersion(state, server_version) {
            state.server_version = server_version;
        },
        setIsOutOfDate(state, out_of_date) {
            state.is_out_of_date = out_of_date;
        },
        postponeUpdate(state, should_pospone = true) {
            if (!should_pospone) {
                state.postponed_until = null;

                return;
            }

            state.postponed_until = new Date(Date.now() + state.postpone_duration);
        }
    },
};
