import Translations from "@/lib/Translations";
import { Pad } from "../Misc";

const $t = Translations.Get;

const DateTime = {};

DateTime.ConvertDateToHumanReadable = (input, day = true, date = true, month = true, year = true) => {
    let stamp = [];

    if (day) {
        stamp.push($t(DateTime.GetDayName(input.getDay())));
    }

    if (date) {
        stamp.push(input.getDate());
    }

    if (month) {
        stamp.push($t(DateTime.GetMonthName(input.getMonth())));
    }

    if (year) {
        stamp.push(input.getFullYear());
    }

    return stamp.join(" ");
};

DateTime.DayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];

/**
 * Converts string to new Date() object
 * ! Replaces spaces to "T" to support iOS
 *
 * @param   {string}  input  Input datestamp
 *
 * @return  {Date}           Date
 */
DateTime.Date = (input) => {
    if(typeof input === "string" || input instanceof String) {
        return new Date(input.replace(" ", "T"));
    }

    return new Date(input);
};

DateTime.ShortDayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

DateTime.GetDayName = (dayIndex) => DateTime.DayNames[dayIndex];

DateTime.GetShortDayName = (dayIndex) => DateTime.ShortDayNames[dayIndex];

DateTime.MonthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

DateTime.ShortMonthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

DateTime.GetMonthName = (monthIndex) => DateTime.MonthNames[monthIndex];

DateTime.GetShortMonthName = (monthIndex) => DateTime.ShortMonthNames[monthIndex];

DateTime.Now = () => new Date();

DateTime.NowWithoutTime = () => {
    const now = DateTime.Now();
    now.setSeconds(0);
    now.setMinutes(0);
    now.setHours(0);
    now.setMilliseconds(0);

    return now;
};

DateTime.ParseDate = (day, month, year) => {
    return `${Pad(day, 2)}-${Pad(month, 2)}-${year}`;
};

DateTime.FormatToDate = (date) => {
    let month = date.getMonth() + 1; // Month starts counting from 0 so we at 1
    month = Pad(month, 2);

    const day = Pad(date.getDate(), 2);

    return `${day}-${month}-${date.getFullYear()}`;
};

DateTime.GetNextDays = (count, input = null) => {
    if (!input) {
        input = DateTime.NowWithoutTime();
    }
    const dates = [];

    for (let i = 0; i < count; i++) {
        const date = new Date(input.getTime());
        date.setDate(date.getDate() + i);
        dates.push(date);
    }

    return dates;
};

/**
 * Gets weeknumber by date
 *
 * @param   {Date}    input Date object
 *
 * @return  {Number}        Returns associated weeknumber
 */
DateTime.getWeekNumber = (input) => {
    let date = new Date(input.getTime());
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
    let week1 = new Date(date.getFullYear(), 0, 4);

    return (
        1 +
        Math.round(
            ((date.getTime() - week1.getTime()) / 86400000 -
                3 +
                ((week1.getDay() + 6) % 7)) /
                7
        )
    );
};

export default DateTime;
