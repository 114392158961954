export default {
    namespaced: true,
    state: {
        preSearchFilters: {},
        selectedFilters: {},
        query: "",
        page: 0,
        totalPages: null,
        totalHits: null,
        currentHitsCount: null,
        presearchHitsCount: 0,
        hits: [],
        scrollPosition: 0, // on the search page we want to remember the scroll position. other pages don't need this so we store it here
    },
    actions: {
        /**
         * add single filter value
         *
         * @param   {[type]}  dispatch
         * @param   {Object}  filterData data for the filter
         * @param   {string}  filterData.filterSlug name of the filter
         * @param   {string|Object}  filterData.value Value
         * @param   {int}  filterData.value.min Min value for range
         * @param   {int}  filterData.value.max Max value for range
         * @param   {string}  filterData.filterType  Type of the filter
         *
         * @return  {void}
         */
        addFilter({dispatch}, filterData) {
            const {value, filterSlug, filterType = "slider"} = filterData;

            if (!value) {
                throw new Error("Value is required");
            }

            if (!filterSlug) {
                throw new Error("Filter slug is required");
            }

            if (filterType === "slider") {
                dispatch("addRangeFilter", {
                    filterSlug,
                    min: value.min,
                    max: value.max
                });
                return;
            }

            dispatch("addListFilter", {
                filterSlug,
                value
            });
        },
        /**
         * @param {string} filterSlug
         * @param {int} min
         * @param {int} max
         */
        addRangeFilter({commit, state}, {filterSlug, min, max}) {
            if (!state.preSearchFilters[filterSlug]) {
                commit("createRangeFilter", {
                    filterSlug,
                    min,
                    max
                });
                return;
            }

            commit("addRangeValueToFilter", {
                filterSlug,
                min,
                max
            });
        },
        /**
         * @param {string} filterSlug
         * @param {string} value
         */
        addListFilter({commit, state}, {filterSlug, value}) {
            if (!state.preSearchFilters[filterSlug]) {
                commit("createListFilter", {
                    filterSlug,
                    value
                });
                return;
            }

            commit("addValueToListFilter", {
                filterSlug,
                value
            });
        },
        addToHits({commit, state}, hits) {
            const totalHits = state.hits.concat(hits);
            commit("setHits", totalHits);
        }

    },
    getters: {
        getSelectedFilters: state => state.selectedFilters,
        getPreSearchFilters: state => state.selectedFilters,
        getQuery: state => state.query,
        getPage: state => state.page,
        getTotalPages: state => state.totalPages,
        getTotalHits: state => state.totalHits,
        getcurrentHitsCount: state => state.currentHitsCount,
        getPresearchHitsCount: state => state.presearchHitsCount,
        getScrollPosition: state => state.scrollPosition,
    },
    mutations: {
        addValueToListFilter(state, {filterSlug, value}) {
            let original = Object.assign({...state.preSearchFilters});
            original[filterSlug].values.push(value);

            state.preSearchFilters = original;
        },
        addRangeValueToFilter(state, {filterSlug, min, max}) {
            let original = Object.assign({...state.preSearchFilters});

            original[filterSlug].values = {min, max};

            state.preSearchFilters = original;
        },
        removeFilter(state, {filterSlug}) {
            let original = Object.assign({...state.preSearchFilters});

            delete original[filterSlug];

            state.preSearchFilters = original;
        },
        removeListValue(state, {filterSlug, value}) {
            if (!state.preSearchFilters[filterSlug]) {
                return;
            }

            // Function should only be usable by a list item
            if (state.preSearchFilters[filterSlug].type !== "list") {
                return;
            }

            let original = Object.assign({...state.preSearchFilters});
            original[filterSlug].values = original[filterSlug].values.filter((usedValue) => {
                return value !== usedValue;
            });

            state.preSearchFilters = original;
        },
        createListFilter(state, {filterSlug, value}) {
            let original = Object.assign({...state.preSearchFilters});
            original[filterSlug] = {
                type: "list",
                values: [value]
            };

            state.preSearchFilters = original;
        },
        createRangeFilter(state, {filterSlug, min, max}) {
            let original = Object.assign({...state.preSearchFilters});

            original[filterSlug] = {
                type: "range",
                values: {
                    min: min,
                    max: max
                }
            };

            state.preSearchFilters = original;
        },
        removeRangeFilter(state, {filterSlug}) {
            let original = Object.assign({...state.preSearchFilters});

            delete original[filterSlug];

            state.preSearchFilters = original;
        },
        setQuery(state, query) {
            state.query = query;
        },
        /**
         * MakePreNotPreAnymore
         * @param state
         */
        overrideSelectedWithPreSearch(state) {
            state.selectedFilters = state.preSearchFilters;
        },
        resetPreSearchFilters(state) {
            state.preSearchFilters = [];
        },
        setPage(state, page) {
            state.page = page;
        },
        setTotalHits(state, totalHits) {
            state.totalHits = totalHits;
        },
        setTotalPages(state, totalPages) {
            state.totalPages = totalPages;
        },
        setHits(state, hits) {
            state.hits = hits;
        },
        setcurrentHitsCount(state, hits) {
            state.currentHitsCount = hits;
        },
        setPresearchHitsCount(state, hits) {
            state.presearchHitsCount = hits;
        },
        setScrollPosition(state, scrollPosition) {
            state.scrollPosition = scrollPosition;
        }
    },
};
