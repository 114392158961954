import { SnakeToPascal } from "@/lib/Misc";

export default class DataObject {
    constructor(data = null) {
        this._data = data;

        for (const _key in data) {
            if (Object.hasOwnProperty.call(data, _key)) {
                let key = SnakeToPascal(_key);

                let methodName =
                    "get" + key.charAt(0).toUpperCase() + key.slice(1);

                if (this[methodName] === undefined) {
                    this[methodName] = () => this.getData(_key);
                }
            }
        }
    }

    getData(name) {
        if (!name) {
            return this._data;
        }

        let path = name.split("/");
        let data = this._data;

        for (let i = 0; i < path.length; i++) {
            const part = path[i];

            if (data[part] === undefined || (i !== path.length -1 && data[part] === null)) {
                return undefined;
            }

            data = data[part];
        }

        return data;
    }

    setData(name, value) {
        if (!value) {
            // presume it's a root replace
            value = name;
            this._data = value;
            return;
        }

        this._data[name] = value;
    }
}
