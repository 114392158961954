module.exports = {
    api_url:
        process.env["VUE_APP_API_URL"] ||
        "https://backend.projectgezond.nl/api",
    media_url:
        process.env["VUE_APP_MEDIA_URL"] ||
        "https://backend.projectgezond.nl",
    gtm_id: "GTM-PBP862V",
    language: "nl_NL",
    algolia: {
        defaultIndex: "recipe_sort_by_created_at_desc",
        appid: "BV9QSKU6J9",
        apikey: "d69328d1af964622d88f922486c445e3",
        indexList: {
            recipe_sort_by_created_at_desc: {
                title: "Recipe sort by created at desc",
                sortTitle: "New-old",
            },
            recipe_sort_by_created_at_asc: {
                title: "Recipe sort by created at asc",
                sortTitle: "Old-new",
            },
            recipe_sort_by_kcal_asc: {
                title: "Recipe sort by Kcal acs",
                sortTitle: "Kcal: low to high",
            },
            recipe_sort_by_kcal_desc: {
                title: "Recipe sort by Kcal desc",
                sortTitle: "Kcal: high to low",
            },
            recipe_sort_by_rating_desc: {
                title: "Recipe sort by rating desc",
                sortTitle: "Highly rated",
            },
            recipe_sort_by_rating_asc: {
                title: "Recipe sort by rating desc",
                sortTitle: "Badly rated",
            },
        },
    },
    sentry: {
        dsn: "https://a1dbd5edbef34548992f59ac5542749b@o1210760.ingest.sentry.io/6299304",
        tracingOrigins: [
            "app-v2.projectgezond.nl",
            "projectgezond-pwa.mooore-test.nl",
            "app.projectgezond.meekijken.net",
        ],
    },
    comments: {
        admin_name: "Project Gezond",
    },
    loaders: {
        blacklist: [
            "/recipes/favorites",
            "/day-menus/favorites",
            "/week-menus/weeks",
            "/day-menu-planned/all",
            "/users/log",
            "/recipes/filters/",
        ],
    },
};
