<template>
    <div class="toasts-container">
        <transition-group name="fade" mode="out-in">
            <div
                v-for="toast in getToasts"
                :key="toast.message"
                :class="`toast -${toast.type}`">
                {{ toast.message }}
            </div>
        </transition-group>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters("toasts", ["getToasts"])
    }
};
</script>

<style lang="scss">
    @import "index";
</style>
