<template>
    <div>
        <div class="updater-container" v-if="shouldDisplayUpdater">
            <Modal :title="$t('New update available')" @close="dismissUpdate">
                <template v-slot:modal-header-button-start>
                    <button @click="dismissUpdate" class="btn -icon-only">
                        <iconLeft />
                    </button>
                </template>
                <p>
                    {{
                        $t(
                            "There's a new update available, would you like to update?"
                        )
                    }}
                </p>
                <p
                    v-html="
                        $t('New version is: <strong>{version}</strong>', {
                            version: readableServerVersion,
                        })
                    "
                ></p>

                <div class="buttons">
                    <button
                        class="btn -link -lg"
                        @click.prevent="dismissUpdate"
                    >
                        {{ $t("No thanks") }}
                    </button>
                    <button class="btn -accent -lg" @click="refresh">
                        {{ $t("Update now") }}
                    </button>
                </div>
            </Modal>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";

import Modal from "@/components/Modal";
import iconLeft from "@/assets/icons/icon-arrow-left.svg?inline";

export default {
    name: "UpdaterContainer",
    components: {
        Modal,
        iconLeft,
    },
    computed: {
        ...mapGetters("updater", [
            "shouldDisplayUpdater",
            "readableServerVersion",
        ]),
        ...mapState("updater", ["server_version"]),
    },
    methods: {
        ...mapMutations("updater", ["postponeUpdate", "setIsOutOfDate"]),

        dismissUpdate() {
            this.postponeUpdate(true);
        },
        async refresh() {
            if (
                navigator.serviceWorker &&
                navigator.serviceWorker.getRegistrations
            ) {
                const registrations = await navigator.serviceWorker.getRegistrations();

                for (let registration of registrations) {
                    registration.unregister();
                }
            }

            document.location.reload();
        },
    },
};
</script>
<style lang="scss">
@import "index";
</style>
