<template>
    <modal
        :title="$t('Personal Info')"
        :subtitle="getUser.getFullName()"
        class="personal-info-modal"
        v-if="getUser && user"
    >
        <template v-slot:modal-header-button-start>
            <button @click="$emit('close')" class="btn -icon-only">
                <iconLeft />
            </button>
        </template>

        <div class="fields">
            <div
                class="field"
                v-for="(field, field_key) in settings"
                :key="field_key"
            >
                <label :for="field_key">{{ field.label }}</label>
                <div :class="`input ${errors[field_key] ? 'has-error' : ''}`">
                    <div class="input-content">
                        <template v-if="field.type === 'number'">
                            <input
                                type="number"
                                :id="field_key"
                                v-model="user[field_key]"
                            />
                        </template>
                        <template v-else-if="field.type === 'options'">
                            <select :id="field_key" v-model="user[field_key]">
                                <option
                                    v-for="(option, option_key) in field.options"
                                    :key="option_key"
                                    :value="option_key"
                                >
                                    {{ option }}
                                </option>
                            </select>
                        </template>
                        <template v-else-if="field.type === 'date'">
                            <span class="flex">
                                <input
                                    type="number"
                                    :id="field_key"
                                    v-model="user[field_key][0]"
                                />
                                <input
                                    type="number"
                                    :id="`${field_key}_2`"
                                    v-model="user[field_key][1]"
                                />
                                <input
                                    type="number"
                                    :id="`${field_key}_3`"
                                    v-model="user[field_key][2]"
                                />
                            </span>
                        </template>
                        <template v-else>
                            <input
                                type="text"
                                :id="field_key"
                                v-model="user[field_key]"
                            />
                        </template>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex justify-center mt-10">
            <button class="btn -accent -lg" @click="save">
                <span>{{ $t('Save information') }}</span>
            </button>
        </div>
    </modal>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import Modal from "@/components/Modal";
import iconLeft from "@/assets/icons/icon-arrow-left.svg?inline";
import DateTime from "@/lib/misc/DateTime";
import Validator from "@/lib/Validator";
import UsersApi from "@/lib/api/Users";
import User from "@/models/User";
import Translations from "@/lib/Translations";
const $t = Translations.Get;

export default {
    components: {
        Modal,
        iconLeft,
    },
    data() {
        return {
            errors: {},
            user: null,
            settings: {
                first_name: {
                    label: this.$t("First Name"),
                    validate: Validator.NotEmpty
                },
                last_name: {
                    label: this.$t("Last Name"),
                    validate: Validator.NotEmpty
                },
                gender: {
                    label: this.$t("Gender"),
                    type: "options",
                    options: {
                        0: this.$t("Female"),
                        1: this.$t("Male"),
                    },
                    validate(val) {
                        return ["0","1"].includes(val);
                    }
                },
                birthday: {
                    label: this.$t("Birthdate"),
                    type: "date",
                    validate: Validator.IsDate
                },
                height: {
                    label: this.$t("Height in cm"),
                    type: "number",
                    validate(val) {
                        if (val < 100 || val > 250) {
                            return $t("Height must be between 100 and 250");
                        }
                        return true;
                    }
                },
                diet_start_at: {
                    label: this.$t("Start date"),
                    type: "date",
                    validate: Validator.IsDate
                },
                start_weight: {
                    label: this.$t("Start Weight"),
                    type: "number",
                    validate(val) {
                        if (!(val >= 30 && val <= 300)) {
                            return $t("Weight must be between 30 and 300");
                        }
                        return true;
                    }
                },
                end_weight: {
                    label: this.$t("Target Weight"),
                    type: "number",
                    validate(val) {
                        if (!(val >= 30 && val <= 300)) {
                            return $t("Weight must be between 30 and 300");
                        }
                        return true;
                    }
                },
                activity: {
                    label: this.$t("How often do you exercise?"),
                    type: "options",
                    options: {
                        0: this.$t("I don't exercise"),
                        2: this.$t("1 to 3 hours a week"),
                        4: this.$t("3 to 5 hours a week"),
                        6: this.$t("6 to 7 hours a week"),
                    },
                    validate(val) {
                        return ["0","2","4","6"].includes(val);
                    }
                },
            },
        };
    },
    computed: {
        ...mapGetters("user", ["getUser"]),
    },
    mounted() {
        this.user = {
            activity: (this.getUser.getActivity() || 0).toString(),
            birthday: DateTime.FormatToDate(
                DateTime.Date(this.getUser.getBirthday())
            ).split("-"),
            diet_start_at: DateTime.FormatToDate(
                DateTime.Date(this.getUser.getDietStartAt().date)
            ).split("-"),
            end_weight: this.getUser.getEndWeight(),
            first_name: this.getUser.getFirstName(),
            gender: this.getUser.getGender() ? "1" : "0",
            height: this.getUser.getHeight(),
            last_name: this.getUser.getLastName(),
            start_weight: this.getUser.getStartWeight(),
        };
    },
    methods: {
        ...mapMutations("user", ["setUser"]),
        async save() {
            let _userout = {
                ...this.user,
                birthday: DateTime.ParseDate(...this.user.birthday),
                diet_start_at: DateTime.ParseDate(...this.user.diet_start_at),
            };

            let schema = Object.assign({...this.settings});
            Object.keys(schema).map((key) => {
                schema[key] = schema[key].validate;
            });

            this.errors = Validator.Validate(
                _userout,
                schema
            );

            if (Object.values(this.errors).length > 0) {
                this.$toast({
                    message: Object.values(this.errors)[0],
                    type: "error",
                });
                return;
            }

            const resp = await UsersApi.UpdateUser(_userout, this.getUser.getId())
                .catch(e => {
                    let vals = Object.values(e);

                    if (vals[0]) {
                        this.$toast({
                            message: vals[0][0],
                            type: "error",
                        });
                    }
                    return;
                });

            if (resp) {
                this.setUser(new User(resp));
                this.$emit("close");
                this.$toast({ type: "success", message: this.$t("Your information has been updated.") });
            }
        },
    }
};
</script>
