const LocalStorage = {};

LocalStorage._storage = null;

LocalStorage.GetStorage = () => {
    if (LocalStorage._storage) {
        return LocalStorage._storage;
    }

    try {
        LocalStorage._storage = window.localStorage;
    }
    catch (err) {
        // * Localstorage is not supported/disabled
        return null;
    }

    return LocalStorage._storage;
};

LocalStorage.GetItem = (key) => {
    return LocalStorage.GetStorage().getItem(key);
};

LocalStorage.SetItem = (key, value) => {
    return LocalStorage.GetStorage().setItem(key, value);
};

LocalStorage.RemoveItem = (key) => {
    return LocalStorage.GetStorage().removeItem(key);
};

export default LocalStorage;
