import DateTime from "@/lib/misc/DateTime";

const SnakeToPascal = (string) => {
    return string
        .split("/")
        .map((snake) =>
            snake
                .split("_")
                .map(
                    (substr) => substr.charAt(0).toUpperCase() + substr.slice(1)
                )
                .join("")
        )
        .join("/");
};

const Pad = (num, size) => {
    return ("0" + num).slice(-1 * size);
};

const NumberToCommaString = (str) => {
    return `${str}`.replace(/\./g, ",");
};

export {
    DateTime,
    SnakeToPascal,
    Pad,
    NumberToCommaString
};
