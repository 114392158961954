import HTTPClient from "@/lib/HTTPClient";
import User from "@/models/User";
import WeightLog from "@/models/WeightLog";

const Users = {};

Users.GetUser = async function() {
    let { data, status } = await HTTPClient.Get("/users/details");

    if (status !== 200) {
        return null;
    }

    return new User(data);
};

/**
 * @description PUT: update the users information
 *
 * @param userID - TOOD: PRO-587
 */
Users.UpdateUser = async function(body, userID) {
    let { data, status } = await HTTPClient.Put(`/users/${userID}/details`, {
        data: {
            introduction: false,
            ...body,
        },
    });

    if (status != 201) {
        throw data.error || data.errors;
    }

    return data;
};

Users.UpdatePassword = async function(
    newPassword,
    newPassword_confirmation,
    password
) {
    let { data, status } = await HTTPClient.Put("/user/password", {
        data: {
            newPassword,
            newPassword_confirmation,
            password,
        },
    });

    if (status != 200) {
        throw data.error || data.errors;
    }

    return data === "updated";
};

Users.ForgotPassword = async function(email) {
    let {status, data} = await HTTPClient.Post("/password/email", {
        data: {
            email,
        },
    });

    if (status !== 200) {
        throw data.error || data.errors;
    }

    return data;
};

Users.GetLog = async function(page = 1) {
    let { status, data } = await HTTPClient.Get("/users/log", {
        params: {
            page,
        },
    });

    if (status !== 200) {
        return [];
    }

    data.data = data.data.map((i) => new WeightLog(i));

    return data;
};

Users.AddLog = async function(timestamp, weight) {
    let resp = await HTTPClient.Post("/users/log", {
        data: {
            day: timestamp,
            weight,
        },
    });

    if (resp.status !== 200) {
        return { error: resp.data };
    }

    return resp;
};

Users.RemoveLog = async function(logId) {
    let resp = await HTTPClient.Delete(`/users/log/${logId}`);

    if (resp.status !== 200) {
        return { error: resp.data };
    }

    return resp;
};

Users.RemoveAllLogs = async function() {
    let resp = await HTTPClient.Delete("/users/log/destroy-all");

    if (resp.status !== 200) {
        return { error: resp.data };
    }

    return resp;
};

export default Users;
