import LocalStorage from "./LocalStorage";

const Authentication = { };
const STORAGE_TOKEN_KEY = "sessionToken";

Authentication.GetSessionToken = () => {
    return LocalStorage.GetItem(STORAGE_TOKEN_KEY);
};

Authentication.Logout = () => {
    return LocalStorage.RemoveItem(STORAGE_TOKEN_KEY);
};

export default Authentication;
