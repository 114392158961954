<template>
    <header
        :class="[theme ? `-${theme}` : '', shouldScroll ? '' : '-scroll']">
        <div class="button-container" v-if="hasButtonStart || hasButtonEnd">
            <slot name="button-start"></slot>
        </div>
        <div class="header-content">
            <h1
                class="title ellipsis"
                v-if="title"
                v-html="title"></h1>
            <p
                class="subtitle"
                v-if="subtitle"
                v-html="subtitle"></p>

            <slot name="header-content"></slot>
        </div>
        <div class="button-container" v-if="hasButtonStart || hasButtonEnd">
            <slot name="button-end"></slot>
        </div>
    </header>
</template>

<script>
export default {
    props: {
        title: String,
        subtitle: String,
        theme: String,
        scrollposition: {
            default: 0,
            type: Number
        }
    },
    name: "Header",
    data() {
        return {
            shouldScroll: true,
        };
    },
    computed: {
        hasHeaderContent() {
            return !!this.$slots["header-content"];
        },
        hasButtonStart() {
            return !!this.$slots["button-start"];
        },
        hasButtonEnd() {
            return !!this.$slots["button-end"];
        },
    },
    created() {
        window.addEventListener("scroll", this.onPageScroll);
    },
    unmounted() {
        window.removeEventListener("scroll", this.onPageScroll);
    },
    methods: {
        onPageScroll() {
            this.shouldScroll = (window.scrollY <= this.scrollposition);
        }
    }
};
</script>

<style lang="scss">
    @import "index";
</style>
