export default {
    namespaced: true,
    state: {
        toastId: 0,
        toasts: [],
    },
    actions: {
        /**
         * Send a toast
         *
         * @param   {[type]}  commit           [commit description]
         * @param   {Object}  payload          Toast
         * @param   {[type]}  payload.type     default, success or error
         * @param   {[type]}  payload.message  Toasts' Message
         *
         * @return  {void}
         */
        sendToast({ commit, state }, args) {
            const {type = "default", message, duration = 2000} = args;
            if (!message) {
                throw new Error("Toast message is required");
            }

            state.toastId++;

            let id = state.toastId;

            commit("addToast", { id, type, message, duration });

            setTimeout(() => {
                commit("removeToastById", id);
            }, duration);
        }
    },
    getters: {
        getToasts: state => state.toasts,
    },
    mutations: {
        addToast(state, payload) {
            state.toasts.push(payload);
        },
        removeToastById(state, id) {
            state.toasts = state.toasts.filter(toast => toast.id !== id);
        }
    },
};
