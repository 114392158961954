<template>
    <div :class="`page-wrapper ${isLoading ? 'app-loading-backdrop' : ''}`">
        <toasts />
        <updater-container />
        <unsupported-version />
        <local-storage-not-supported />
        <install-popup />
        <introduction v-if="getUser" />
        <router-view :key="$route.params" />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import PWA from "@/lib/PWA";
import Toasts from "@/components/Toasts";
import UpdaterContainer from "@/components/UpdaterContainer";
import InstallPopup from "@/components/Popups/InstallPopup";
import Introduction from "@/components/Introduction";
import UnsupportedVersion from "@/components/Popups/UnsupportedVersion";
import LocalStorageNotSupported from "@/components/Popups/LocalStorageNotSupported";

export default {
    components: {
        Toasts,
        UpdaterContainer,
        InstallPopup,
        Introduction,
        UnsupportedVersion,
        LocalStorageNotSupported
    },
    computed: {
        ...mapGetters("user", ["getUser"]),
        ...mapGetters("loader", ["isLoading"]),
    },
    methods: {
        isIos12OrLower() {
            return PWA.isIos12OrLower();
        },
    },
};
</script>

<style lang="scss">
@import "styles/main";
</style>
