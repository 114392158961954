import UsersApi from "@/lib/api/Users";
import * as Sentry from "@sentry/vue";

export default {
    namespaced: true,
    state: {
        current_user: null,
        current_user_promise: null,
        has_unsupported_version: false,
        dismissed_unsupported_message: false,
        isMember: false,
    },
    getters: {
        getUser: (state) => state.current_user,
        getIsMember: (state) => state.isMember,
    },
    mutations: {
        setUser(state, user) {
            state.current_user = user;
        },
        setIsMember(state, isMember) {
            state.isMember = isMember;
        }
    },
    actions: {
        async getUserInfo({ commit, state }) {
            state.current_user_promise = await UsersApi.GetUser();

            if (state.current_user_promise === null) {
                return;
            }

            state.current_user_promise = await state.current_user_promise;

            Sentry.setUser({ email: state.current_user_promise.getEmail() });
            commit("setUser", state.current_user_promise);
            commit("setIsMember", state.current_user_promise.isMember());
        },
    },
};
