<template>
    <div>
        <div class="unsupportversion-container" v-if="shouldDisplayMessage">
            <Modal :title="$t('Unfortunately...')" @close="dismissVersion">
                <template v-slot:modal-header-button-start>
                    <button @click="dismissVersion" class="btn -icon-only">
                        <iconLeft />
                    </button>
                </template>
                <p>
                    {{
                        $t("Your device runs on the iOS {version} operating system. Our app does not work optimally because of this. Update to a newer one if possible iOS version to better use the app.", { version: getVersion })
                    }}
                </p>
                <div class="buttons">
                    <button class="btn -link -lg" @click.prevent="dismissVersion">
                        {{ $t("Clear, close this message") }}
                    </button>
                </div>
            </Modal>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import Modal from "@/components/Modal";
import iconLeft from "@/assets/icons/icon-arrow-left.svg?inline";
import PWA from "@/lib/PWA";
import LocalStorage from "@/lib/LocalStorage";

export default {
    name: "UnsupportedVersion",
    components: {
        Modal,
        iconLeft,
    },
    computed: {
        ...mapGetters("unsupportversion", ["shouldDisplayMessage"]),
        getVersion() {
            return PWA.getIosVersion();
        },
    },
    methods: {
        ...mapMutations("unsupportversion", [
            "setHasUnsupportedVersion",
            "setHasDismissedUssuportedMessage",
        ]),
        dismissVersion() {
            this.setHasDismissedUssuportedMessage(true);
            LocalStorage.SetItem("dismissed_unsupported_message", true);
        },
    },
    mounted() {
        const dismissedUnsupportedMessage = LocalStorage.GetItem(
            "dismissed_unsupported_message"
        );

        this.setHasDismissedUssuportedMessage(dismissedUnsupportedMessage);
        this.setHasUnsupportedVersion(PWA.isIos12OrLower());
    },
};
</script>
<style lang="scss">
@import "index";
</style>
