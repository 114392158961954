export default {
    "{activeSlide} of {totalItems}": "{activeSlide} of {totalItems}",
    "{age} years": "{age} years",
    "{bmi_min} and {bmi_max}.": "{bmi_min} and {bmi_max}",
    "{from} and {to}": "{from} and {to}",
    "{height} cm": "{height} cm",
    "{kg} kg": "{kg} kg",
    "{recipeTitle} was added as favorite recipe": "{recipeTitle} was added as favorite recipe",
    "{recipeTitle} was removed as favorite recipe": "{recipeTitle} was removed as favorite recipe",
    "{weight} kilo": "{weight} kilo",
    "1 to 3 hours a week": "1 to 3 hours a week",
    "100% free and after 14 days we will leave you alone, unless of course you are convinced of Project Gezond!": "100% free and after 14 days we will leave you alone, unless of course you are convinced of Project Gezond!",
    "3 to 5 hours a week": "3 to 5 hours a week",
    "6 to 7 hours a week": "6 to 7 hours a week",
    "a day. To lose weight we recommend subtracting (maximum) 500 Kcal from this. You then arrive at": "To lose weight we recommend subtracting (maximum) 500 Kcal from this. You then arrive at",
    "Add to home screen": "Add to home screen",
    "Add weightlog": "Add weightlog",
    "Add": "Add",
    "Age in years": "Age in years",
    "Age must be between 1 and 150": "Age must be between 1 and 150",
    "All the recipes in a list": "All the recipes in a list",
    "and a weight of": "and a weight of",
    "and choose": "and choose",
    "and try it free for 14 days": "and try it free for 14 days",
    "Apr": "Apr", // @required
    "April": "April", // @required
    "Are you sure you want to remove all of your weight logs?": "Are you sure you want to remove all of your weight logs?",
    "Are you sure you want to schedule this daymenu?": "Are you sure you want to schedule this daymenu?",
    "At least 8 characters": "At least 8 characters",
    "At least contain 1 special character": "At least contain 1 special character",
    "At this moment you have a": "At this moment you have a",
    "Aug": "Aug", // @required
    "August": "August", // @required
    "Badly rated": "Badly rated", // @required
    "Based on your data your kcal requirement is": "Based on your data, your kcal requirement is",
    "Birthdate": "Birthdate",
    "BMI (Body mass index)": "BMI (Body mass index)",
    "BMI {eq}": "BMI {eq}",
    "BMI of {bmi}.": "BMI of {bmi}.",
    "BMI of {from} to {to}": "BMI of {from} to {to}",
    "BMI": "BMI",
    "Breakfast": "Breakfast", // @required
    "Calculate BMI": "Calculate BMI",
    "Calculate your calorieneed": "Calculate your calorieneed",
    "Calculate": "Calculate",
    "Calorie need": "Calorie need",
    "Calorieneed": "Calorieneed",
    "Cancel": "Cancel",
    "Change Password": "Change Password",
    "Choose a week": "Choose a week",
    "Click here": "Click here",
    "Clear, close this message": "Clear, close this message",
    "Confirm registration": "Confirm registration",
    "Continue": "Continue",
    "Current Password": "Current Password",
    "Date": "Date",
    "Day after tomorrow": "Day after tomorrow",
    "Day menu was added as favorite": "Day menu was added as favorite",
    "Day menu was removed as favorite": "Day menu was removed as favorite",
    "Day": "Day",
    "Daymenu": "Daymenu",
    "Daymenus with this recept": "Daymenus with this recept",
    "Dec": "Dec", // @required
    "December": "December", // @required
    "Dinner": "Dinner", // @required
    "E.g. 170": "E.g. 170",
    "E.g. 30": "E.g. 30",
    "E.g. 70": "E.g. 70",
    "Eating delicious food every day and losing weight in the meantime?": "Eating delicious food every day and losing weight in the meantime?",
    "Email" : "Email",
    "Enter your current password": "Enter your current password",
    "Enter your new password again": "Enter your new password again",
    "Enter your new password": "Enter your new password",
    "extremely overweight": "extremely overweight",
    "Extremely overweight": "Extremely overweight",
    "fairly overweight": "fairly overweight",
    "Fairly overweight": "Fairly overweight",
    "Feb": "Feb", // @required
    "February": "February", // @required
    "Female": "Female",
    "Files": "Files",
    "Fill in details": "Fill in details",
    "Fill in your length": "Fill in your length",
    "Fill in your weight": "Fill in your weight",
    "Filter": "Filter",
    "Find a recipe": "Find a recipe",
    "First Name": "First Name",
    "Firstname": "Firstname",
    "For a": "For a",
    "Forgot password?": "Forgot password?",
    "Fr": "Fr", // @required
    "Fri": "Fri", // @required
    "Friday": "Friday", // @required
    "from": "from",
    "Gender": "Gender",
    "Good afternoon": "Good afternoon",
    "Good evening": "Good evening",
    "Good morning": "Good morning",
    "Good night": "Good night",
    "Grocery list": "Grocery list",
    "healthy weight": "healthy weight",
    "Healthy weight": "Healthy weight",
    "Height in cm": "Height in cm",
    "Height must be between 100 and 250": "Height must be between 100 and 250",
    "Help with diet": "Help with diet",
    "Hi": "Hi!",
    "Hide password": "Hide password",
    "Highly rated": "Highly rated", // @required
    "hour": "hour", // @required
    "How often do you exercise?": "How often do you exercise?",
    "I don't exercise": "I don't exercise",
    "If you want to lose weight, you should eat {result} kcal less": "If you want to lose weight, you should eat {result} kcal less",
    "Ingredients": "Ingredients",
    "Install Project Gezond as app": "Install Project Gezond as app",
    "Jan": "Jan", // @required
    "January": "January", // @required
    "Jul": "Jul", // @required
    "July": "July", // @required
    "Jun": "Jun", // @required
    "June": "June", // @required
    "Kcal from {min} to {max}": "Kcal from {min} to {max}",
    "Kcal to lose weight": "Kcal to lose weight",
    "Kcal: high to low": "Kcal: high to low", // @required
    "Kcal: low to high": "Kcal: low to high", // @required
    "kcal": "kcal",
    "Kcal": "Kcal",
    "kilos to have a healthy weight.": "kilos to have a healthy weight.",
    "Last Name": "Last Name",
    "Length (in cm)": "Length (in cm)",
    "length of": "length of",
    "Let's get started with your personal information. With this we can calculate your calorie needs, and you can easily track your progress. Filling this is only takes one minute.": "Let's get started with your personal information. With this we can calculate your calorie needs, and you can easily track your progress. Filling this is only takes one minute.",
    "Load more recipes": "Load more recipes",
    "Load more weight logs": "Load more weight logs",
    "Log out": "Log out",
    "Login": "Login",
    "Lose 0.5 to 1 kilo per week without feeling hungry": "Lose 0.5 to 1 kilo per week without feeling hungry",
    "Lunch": "Lunch", // @required
    "Male": "Male",
    "Mar": "Mar", // @required
    "March": "March", // @required
    "May": "May", // @required
    "minutes": "minutes", // @required
    "Mo": "Mo", // @required
    "Mon": "Mon", // @required
    "Monday": "Monday", // @required
    "Month": "Month",
    "More info": "More info",
    "More than 250 easy and especially tasty recipes": "More than 250 easy and especially tasty recipes",
    "More": "More",
    "Must be at least 8 characters": "Must be at least 8 characters",
    "Must contain at least one lowercase letter, one uppercase letter, one digit and one special character": "Must contain at least one lowercase letter, one uppercase letter, one digit and one special character",
    "My details": "My details",
    "My favorite daymenus": "My favorite daymenus",
    "My favorite recipes": "My favorite recipes",
    "My PG": "My PG",
    "My planned daymenus": "My planned daymenus",
    "My Project Gezond": "My Project Gezond",
    "My weight log": "My weight log",
    "My Weight Logs": "My Weight Logs",
    "Never difficult calculations, but always enjoy": "Never difficult calculations, but always enjoy",
    "New Password": "New Password",
    "New update available": "New update available",
    "New version is: <strong>{version}</strong>": "New version is: <strong>{version}</strong>",
    "New-old": "New-old", // @required
    "Nibbles": "Nibbles", // @required
    "No recipes found": "No recipes found",
    "No thanks": "No thanks",
    "No": "No",
    "Not a valid e-mail address": "Not a valid e-mail address",
    "Not yet a member of Project Healthy?": "Not yet a member of Project Healthy?",
    "Nov": "Nov", // @required
    "November": "November", // @required
    "Oct": "Oct", // @required
    "October": "October", // @required
    "Ok": "Ok",
    "Old-new": "Old-new", // @required
    "Open daily menus standard in the following kcal variant:": "Open daily menus standard in the following kcal variant:",
    "Other questions": "Other questions",
    "overweight": "overweight",
    "Overweight": "Overweight",
    "Password": "Password",
    "Passwords do not match": "Passwords do not match",
    "Personal Info": "Personal Info",
    "Plan in": "Plan in",
    "Planned daymenu by you": "Planned daymenu by you",
    "Please enter a valid value": "Please enter a valid value",
    "Please fill in a reply": "Please fill in a reply",
    "Please fill in all fields": "Please fill in all fields",
    "Please first fill in all fields": "Please first fill in all fields",
    "Press": "Press",
    "Progress": "Progress",
    "Question": "Question",
    "Questions? info@projectgezond.nl": "Questions? info@projectgezond.nl",
    "Rate this recipe": "Rate this recipe",
    "rating": "rating",
    "ratings": "ratings",
    "Recipe rated succesfully": "Recipe rated succesfully",
    "Recipe": "Recipe", // @required
    "Recipes in this menu": "Recipes in this menu",
    "Recipes in this weekmenu": "Recipes in this weekmenu",
    "Recipes": "Recipes",
    "Remove all weightlog": "Remove all weightlog",
    "Reply on this recipe": "Reply on this recipe",
    "Reply posted": "Reply posted",
    "Reply to the above comment": "Reply to the above comment",
    "Reply to this comment": "Reply to this comment",
    "Reply": "Reply",
    "Reset": "Reset",
    "Sa": "Sa", // @required
    "Sat": "Sat", // @required
    "Saturday": "Saturday", // @required
    "Save as favorite": "Save as favorite",
    "Save information": "Save information",
    "Schedule daymenu": "Schedule daymenu",
    "Search for a specific recipe": "Search for a specific recipe",
    "Search": "Search",
    "seconds": "seconds", // @required
    "Select an option": "Select an option",
    "Send": "Send",
    "Sep": "Sep", // @required
    "September": "September", // @required
    "Show own daymenu": "Show own daymenu",
    "Show password": "Show password",
    "Show Recipe": "Show Recipe",
    "Show Recipes": "Show Recipes",
    "Since {date} ({weight} kg)": "Since {date} ({weight} kg)",
    "Skip": "Skip",
    "Snack": "Snack", // @required
    "Start date": "Start date",
    "Start Weight": "Start Weight",
    "Start": "Start",
    "Su": "Su", // @required
    "Subject": "Subject",
    "Submit reply": "Submit reply",
    "Succesfully added weight log": "Succesfully added weight log",
    "Sun": "Sun", // @required
    "Sunday": "Sunday", // @required
    "Target Weight": "Target Weight",
    "Th": "Th", // @required
    "Thank you!": "Thank you!",
    "Thanks to the six eating moments a day, you will never suffer from an enormous appetite.": "Thanks to the six eating moments a day, you will never suffer from an enormous appetite.",
    "Thanks!": "Thanks!",
    "Thanks": "Thanks",
    "That puts you in the category": "That puts you in the category",
    "That's how you make it": "That's how you make it",
    "the following values apply.": "the following values apply.",
    "The variant of the weekly menu that is closest to this is that of": "The variant of the weekly menu that is closest to this is that of",
    "There is already a daymenu on this date. Do you want to overwrite this?": "There is already a daymenu on this date. Do you want to overwrite this?",
    "There was an error posting your reply.": "There was an error posting your reply.",
    "There's a new update available, would you like to update?": "There's a new update available, would you like to update?",
    "This App functions even better when you install it. Install the Project Gezond App on the home screen.": "This App functions even better when you install it. Install the Project Gezond App on the home screen.",
    "This App functions even better when you install it": "This App functions even better when you install it",
    "This means that with a": "This means that with a",
    "Thu": "Thu", // @required
    "Thursday": "Thursday", // @required
    "Tip {tipIndex}": "Tip {tipIndex}",
    "Today": "Today",
    "Tomorrow": "Tomorrow",
    "Total {totalItems} recipes": "Total {totalItems} recipes",
    "Total 1 recipe": "Total 1 recipe",
    "Total lost weight": "Total lost weight",
    "Try Project Gezond for 14 days for free!": "Try Project Gezond for 14 days for free!",
    "Tu": "Tu", // @required
    "Tue": "Tue", // @required
    "Tuesday": "Tuesday", // @required
    "Unauthenticated.": "Unauthenticated.", // @required
    "underweight": "underweight",
    "Underweight": "Underweight",
    "Unfortunately...": "Unfortunately...",
    "Unlimited personal support, a friendly Facebook group, handy variation lists, a dashboard for your progress and many more extras.": "Unlimited personal support, a friendly Facebook group, handy variation lists, a dashboard for your progress and many more extras.",
    "Update now": "Update now",
    "Uppercase and lowercase characters": "Uppercase and lowercase characters",
    "Use the calculator below to calculate your daily calorie requirement. In the table with results you can see how many calories you can eat per day to <strong>maintain your weight</strong> and how many calories you can eat per day to lose <strong>half a kilo per week</strong >.": "Use the calculator below to calculate your daily calorie requirement. In the table with results you can see how many calories you can eat per day to <strong>maintain your weight</strong> and how many calories you can eat per day to lose <strong>half a kilo per week</strong >.",
    "Use the form below to calculate your BMI.": "Use the form below to calculate your BMI.",
    "Useful links": "Useful links",
    "Version: {version}": "Version: {version}",
    "View and manage all weightlogs": "View and manage all weightlogs",
    "View planned day menus": "View planned day menus",
    "Watch out!": "Watch out!",
    "We have sent you an email with instructions on how to reset your password.": "We have sent you an email with instructions on how to reset your password.",
    "We have worked out every daily menu completely for you. So you don't have to count or keep track of anything yourself.": "We have worked out every daily menu completely for you. So you don't have to count or keep track of anything yourself.",
    "We will send you a link by email to set up your personal passwords. Then you can get started right away.": "We will send you a link by email to set up your personal passwords. Then you can get started right away.",
    "We": "We", // @required
    "Wed": "Wed", // @required
    "Wednesday": "Wednesday", // @required
    "Week {num}": "Week {num}",
    "Week menu": "Week menu",
    "Weekmenus": "Weekmenus",
    "Weight (in kg)": "Weight (in kg)",
    "Weight in kg": "Weight in kg",
    "Weight logs": "Weight logs",
    "Weight must be between 30 and 300": "Weight must be between 30 and 300",
    "Weight": "Weight",
    "Weightlog deleted": "Weightlog deleted",
    "Welcome {user}!": "Welcome {user}!",
    "Welcome back": "Welcome back",
    "Welcome!": "Welcome!",
    "When you don't sport": "When you don't sport",
    "With {min} to {max} hours of sport per week:": "With {min} to {max} hours of sport per week:",
    "with a length of": "with a length of",
    "With the weekly menus and recipes of Project Gezond you will reach your ideal weight with a smile!": "With the weekly menus and recipes of Project Gezond you will reach your ideal weight with a smile!",
    "Write your reply on this recipe": "Write your reply on this recipe",
    "Year": "Year",
    "Yes": "Yes",
    "you can weigh between": "you can weigh between",
    "You get access to over 250 delicious recipes with step-by-step instructions": "You get access to over 250 delicious recipes with step-by-step instructions",
    "You get everything you need to lose weight successfully": "You get everything you need to lose weight successfully",
    "You have a healthy weight if your BMI is between": "You have a healthy weight if your BMI is between",
    "You have planned a daymenu here": "You have planned a daymenu here",
    "You haven't planned a daymenu yet": "You haven't planned a daymenu yet",
    "You'll receive a response within 2 days for your comment or question.": "You'll receive a response within 2 days for your comment or question.",
    "Your information has been updated.": "Your information has been updated.",
    "Your new password requires:": "Your new password requires:",
    "Your password is set, you are ready to login!": "Your password is set, you are ready to login!",
    "Your prefered calorievariant has been set to {calories}": "Your prefered calorievariant has been set to {calories}",
    "Your question": "Your question",
    "Load more comments": "Load more comments",
    "Comments ({num})": "Comments ({num})",
    "Tips & Tricks": "Tips & Tricks",
    "You searched for ''{query}''": "You searched for ''{query}''",
    "Your device runs on the iOS {version} operating system. Our app does not work optimally because of this. Update to a newer one if possible iOS version to better use the app.": "Your device runs on the iOS {version} operating system. Our app does not work optimally because of this. Update to a newer one if possible iOS version to better use the app.",
    "Day menu is now scheduled in": "Day menu is now scheduled in",
    "Page not found": "Page not found",
    "Can't reach server, please check your internet connection and try again.": "Can't reach server, please check your internet connection and try again.",
    "Your device has cookies disabled, this is required for the app to work.": "Your device has cookies disabled, this is required for the app to work.",
    "On Chrome (Android)": "On Chrome (Android)",
    "On your Android phone or tablet, open the Chrome app Chrome.": "On your Android phone or tablet, open the Chrome app Chrome.",
    "At the top right, tap More More and then Settings.": "At the top right, tap More More and then Settings.",
    "Tap Site settings and then Cookies.": "Tap Site settings and then Cookies.",
    "Turn Cookies on.": "Turn Cookies on.",
    "On Safari (iOs)": "On Safari (iOs)",
    "Go to Settings, then scroll down and select “Safari”.": "Go to Settings, then scroll down and select “Safari”.",
    "Scroll down to “Privacy & Security”.": "Scroll down to “Privacy & Security”.",
    "Verify “Block All Cookies” is unticked, click to allow cookies.": "Verify “Block All Cookies” is unticked, click to allow cookies.",
    "Clear the browser cache and reopen the browser.": "Clear the browser cache and reopen the browser.",
};
