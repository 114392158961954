const config = require("@/config");

const { default: langfile } = require(`@/i18n/${config.language}`);

const Translations = { };

const NOTIFY_MISSING_TRANSLATIONS = false;

Translations.Get = (input, vars) => {
    let output = langfile[input] || input;

    if (vars) {
        for (const key in vars) {
            output = output.replace(`{${key}}`, vars[key]);
        }
    }

    if (NOTIFY_MISSING_TRANSLATIONS && input === output) {
        console.warn(`Missing translation for "${input}"`);
    }

    return output;
};

export default Translations;
