<template>
    <div class="modal">
        <div class="modal-wrapper">
            <div class="modal-header" v-if="hasModalHeader">
                <Header
                    :title="title"
                    :subtitle="subtitle"
                    :theme="classHeader"
                >
                    <template v-slot:button-start>
                        <slot name="modal-header-button-start"></slot>
                    </template>
                    <template v-slot:button-end>
                        <slot name="modal-header-button-end"></slot>
                    </template>
                    <template v-slot:header-content>
                        <slot name="header-content"></slot>
                    </template>
                </Header>
            </div>
            <div class="modal-content">
                <slot></slot>
            </div>
            <div class="modal-footer" v-if="hasModalFooter">
                <slot name="modal-footer"></slot>
            </div>
        </div>
        <div class="modal-backdrop" @click="closeModal"></div>
    </div>
</template>

<script>
import Header from "@/components/Header";

export default {
    props: {
        title: String,
        subtitle: String,
        classHeader: String,
    },
    components: {
        Header,
    },
    name: "Modal",
    computed: {
        hasModalFooter() {
            return !!this.$slots["modal-footer"];
        },
        hasModalHeader() {
            return (
                this.$slots["header-content"] ||
                this.$slots["modal-header-button-start"] ||
                this.$slots["modal-header-button-end"] ||
                this.title ||
                this.subtitle
            );
        },
    },
    mounted() {
        document.body.addEventListener("keyup", this.onKeyUp);
        this.bodyModalClass();
    },
    unmounted() {
        document.body.removeEventListener("keyup", this.onKeyUp);
        this.bodyModalClass();
    },
    methods: {
        onKeyUp(e) {
            if (e.code === "Escape") {
                this.closeModal();
            }
        },
        closeModal() {
            this.$emit("close");
        },
        bodyModalClass() {
            this.$nextTick();
            if (document.querySelector(".modal")) {
                document.body.classList.add("modal-active");
            } else {
                document.body.classList.remove("modal-active");
            }
        },
    },
};
</script>

<style lang="scss">
@import "index";
</style>
