import Translations from "@/lib/Translations";
const $t = Translations.Get;

const Validator = {};

// eslint-disable-next-line no-useless-escape
const SPECIAL_CHARACTERS = "$!#{}=\/%@#$%^&*()_+€£¢∞§¶•ªº¡-±~`<>,.;':\"*?&";

Validator.IsEmpty = (string) => {
    return !string || string.length === 0;
};

Validator.NotEmpty = (str) => !Validator.IsEmpty(str);

Validator.IsEmail = (str) => {
    // eslint-disable-next-line no-useless-escape
    const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regex.test(str)) {
        return $t("Not a valid e-mail address");
    }

    return true;
};

Validator.IsDate = (string) => {
    return /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/.test(
        string
    );
};

Validator.IsPassword = (str) => {
    if (str.length < 8) {
        return $t("Must be at least 8 characters");
    }

    const password_regex = new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*["+SPECIAL_CHARACTERS+"])[A-Za-z\\d"+SPECIAL_CHARACTERS+"]{8,}$"
    );

    if (!password_regex.test(str)) {
        return $t("Must contain at least one lowercase letter, one uppercase letter, one digit and one special character");
    }

    return true;
};

Validator.Validate = (data, rules) => {
    let errors = {};

    for (const key in rules) {
        let validators = rules[key];

        if (!validators.length || typeof validators === "function") {
            validators = [validators];
        }

        for (const validator of validators) {
            if (validator.test !== undefined) {
                // regex
                if (!validator.test(data[key])) {
                    errors[key] = $t("Please enter a valid value");
                }
                continue;
            }

            let resp = validator(data[key]);

            if (resp === true) {
                continue;
            }

            if (typeof resp === "string") {
                errors[key] = resp;
                continue;
            }

            errors[key] = $t("Please enter a valid value");
            break;
        }
    }

    return errors;
};

export default Validator;
