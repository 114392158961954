import DataObject from "./DataObject";

export default class User extends DataObject {
    constructor(data) {
        super(data);
    }

    getCustomerSetting(key = null) {
        if (!key) {
            return null;
        }

        return this.getData("customer_settings").find(
            (setting) => setting.key == key
        );
    }

    getCurrentWeight() {
        return (this.getData("current_weight") || 0).toFixed(1);
    }

    getStartWeight() {
        return (this.getData("start_weight") || 0).toFixed(1);
    }

    getCalculatedDefaultCalorie() {
        var calorieOptions = [1250, 1500, 1800, 2100];
        let rec = this.getCalorieRequirement() - 500;

        return calorieOptions.reduce((prev, curr) =>
            Math.abs(curr - rec) < Math.abs(prev - rec) ? curr : prev
        );
    }

    isMember() {
        return Boolean(this.getData("member"));
    }
}
