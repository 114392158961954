export default {
    namespaced: true,
    state: {
        favoriteRecipes: [],
        favoriteRecipeIds: [],
        favoriteDayMenus: [],
        favoriteDayMenuIds: [],
    },
    actions: {
        addFavoriteRecipe({ state }, { recipe }) {
            const original = state.favoriteRecipes;

            const existingRecipe = original.find(
                (item) => item.getId() === recipe.getId()
            );

            if (existingRecipe) {
                console.error(
                    `${recipe.getTitle()} is already exist in the favorite list.
                    No further operation will be performed`
                );
                return;
            }

            //TODO check if we need to resort it
            original.push(recipe);

            state.favoriteRecipes = original;
        },
        removeFavoriteRecipe({ state }, { recipe }) {
            let original = state.favoriteRecipes;

            state.favoriteRecipes = original.filter(
                (item) => item.getId() !== recipe.getId()
            );
        },
        setFavoriteRecipeIds({ state }) {
            state.favoriteRecipeIds = state.favoriteRecipes.map((recipe) =>
                recipe.getId()
            );
        },
        setFavoriteRecipes({ dispatch, commit }, { favoriteRecipes }) {
            commit("setFavoriteRecipes", { favoriteRecipes });
            dispatch("setFavoriteRecipeIds");
        },
        addFavoriteDayMenu({ state }, { dayMenu }) {
            const original = state.favoriteDayMenus;

            const existingRecipe = original.find(
                (item) => item.getId() === dayMenu.getId()
            );

            if (existingRecipe) {
                console.error(
                    `${dayMenu.getTitle()} is already exist in the favorite list.
                    No further operation will be performed`
                );
                return;
            }

            //TODO check if we need to resort it
            original.push(dayMenu);

            state.favoriteDayMenus = original;
        },
        removeFavoriteDayMenu({ state }, { dayMenu }) {
            let original = state.favoriteDayMenus;

            state.favoriteDayMenus = original.filter(
                (item) => item.getId() !== dayMenu.getId()
            );
        },
        setFavoriteDayMenuIds({ state }) {
            state.favoriteDayMenuIds = state.favoriteDayMenus.map((dayMenu) =>
                dayMenu.getId()
            );
        },
        setFavoriteDayMenus({ dispatch, commit }, { favoriteDayMenus }) {
            commit("setFavoriteDayMenus", { favoriteDayMenus });
            dispatch("setFavoriteDayMenuIds");
        },
    },
    mutations: {
        setFavoriteRecipes(state, { favoriteRecipes }) {
            state.favoriteRecipes = favoriteRecipes;
        },
        setFavoriteDayMenus(state, { favoriteDayMenus }) {
            state.favoriteDayMenus = favoriteDayMenus;
        },
    },
};
