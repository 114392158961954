/* globals ServiceWorkerGlobalScope */
const PWA = {};

PWA.DeferredPrompt = null;

PWA.Init = () => {
    window.addEventListener("beforeinstallprompt", (DeferredPrompt) => {
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        DeferredPrompt.preventDefault();

        PWA.DeferredPrompt = DeferredPrompt;
    });
    window.addEventListener("install", () => {
        ServiceWorkerGlobalScope.skipWaiting();
    });
};

PWA.PromptInstall = () => {
    if (!PWA.DeferredPrompt) {
        return;
    }

    PWA.DeferredPrompt.prompt();

    PWA.DeferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
            console.log("User accepted the A2HS prompt");
        } else {
            console.log("User dismissed the A2HS prompt");
        }

        PWA.DeferredPrompt = null;
    });
};

PWA.HasInstallAvailable = () => PWA.DeferredPrompt !== null && !PWA.isApple();

// More like IsProblem
PWA.isApple = () =>
    navigator.userAgent.indexOf("iPhone") > -1 ||
    navigator.userAgent.indexOf("iPad") > -1;

PWA.isIos12OrLower = () => {
    if (!PWA.isApple()) return;

    return PWA.getIosVersion() < 13;
};

PWA.getIosVersion = () => {
    if (!PWA.isApple()) return;

    const v = navigator.userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);
    const version = parseInt(v[1], 10);

    return version;
};

export default PWA;
