export default {
    namespaced: true,
    state: {
        loading_instances: []
    },
    getters: {
        isLoading: state => state.loading_instances.length > 0,
    },
    mutations: {
        setLoading(state, new_loading) {
            Object.keys(new_loading)
                .forEach(key => {
                    const value = new_loading[key];

                    if (value) {
                        state.loading_instances.push(key);
                    }
                    else {
                        state.loading_instances =
                            state.loading_instances
                                .filter(instance => instance !== key);

                        if (state.loading_instances.length === 0) {
                            let splash = document.body.querySelector(".splash");

                            if (splash) {
                                splash.classList.add("-remove");
                                setTimeout(() => {
                                    const splash = document.body.querySelector(".splash");

                                    if (splash) {
                                        splash.remove();
                                    }
                                }, 500);
                            }
                        }
                    }
                });
        }
    },
};
