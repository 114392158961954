import axios from "axios";

import config from "@/config";
import Authentication from "./Authentication";
import store from "@/store";
import Translations from "@/lib/Translations";
const $t = Translations.Get;

const HTTPClient = {};

HTTPClient.WebClient = null;

HTTPClient.Middlewares = [
    import("@/lib/middlewares/updater"),
    import("@/lib/middlewares/auth"),
    import("@/lib/middlewares/loader"),
    import("@/lib/middlewares/sentry"),
];

HTTPClient.Init = async () => {
    HTTPClient.WebClient = axios.create({
        baseURL: config.api_url,
    });

    //TODO make this a middleware
    HTTPClient.WebClient.interceptors.request.use((req) => {
        req.headers = req.headers || {};
        req.headers["Access-Control-Allow-Origin"] = "*"; // CORS
        req.headers["X-Requested-With"] = "XMLHttpRequest"; // Laravel API
        req.headers["Authorization"] =
            `Bearer ${Authentication.GetSessionToken()}`;

        return req;
    });

    // Middlewares
    await Promise.all(
        HTTPClient.Middlewares.map(async (module) => {
            let { default: middleware } = await module;

            if (middleware.request) {
                middleware.request.forEach((fn) => {
                    HTTPClient.WebClient.interceptors.request.use(fn, (e) => {
                        return fn(e.response, e);
                    });
                });
            }

            if (middleware.response) {
                middleware.response.forEach((fn) => {
                    HTTPClient.WebClient.interceptors.response.use(fn, (e) => {
                        return fn(e.response, e);
                    });
                });
            }
        })
    );
};

HTTPClient.MakeRequest = async (url, opts = {}) => {
    if (!HTTPClient.WebClient) {
        await HTTPClient.Init();
    }

    console.warn(`Making request ${opts.method || ""} ${url}`);

    let data = await HTTPClient.WebClient({ url, ...opts }).catch((err) => {
        if (err && err.response && err.response.data) {
            if (err.response.data.length) {
                err.response.data = err.response.data[0];
            }

            let error = err.response.data;

            if (error.error) {
                error = error.error;
            }

            if (typeof error === "string") {
                store.dispatch("toasts/sendToast", {
                    type: "error",
                    message: error,
                });
            }
        }

        if (!err.response) {
            // XHR error
            store.dispatch("toasts/sendToast", {
                type: "error",
                message: $t("Can't reach server, please check your internet connection and try again."),
                duration: 10 * 1000 // 10s
            });

            throw "No internet connection available or XHR error";
        }

        return err.response;
    });

    return data;
};

// Aliases
["Post", "Get", "Delete", "Put"].forEach((method) => {
    HTTPClient[method] = (url, opts = {}) => {
        return HTTPClient.MakeRequest(url, {
            ...opts,
            method: method.toLowerCase(),
        });
    };
});

export default HTTPClient;
