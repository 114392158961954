<template>
    <div v-if="!isSupported">
        <div class="localstoragenotsupported-container">
            <Modal :title="$t('Unfortunately...')">
                <div class="alert flex gap-2">
                    <icon-cookie />
                    <p>{{ $t("Your device has cookies disabled, this is required for the app to work.") }}</p>
                </div>

                <div class="guide">
                    <h2>{{ $t("On Chrome (Android)") }}</h2>
                    <ol>
                        <li>{{ $t("On your Android phone or tablet, open the Chrome app Chrome.") }}</li>
                        <li>{{ $t("At the top right, tap More More and then Settings.") }}</li>
                        <li>{{ $t("Tap Site settings and then Cookies.") }}</li>
                        <li>{{ $t("Turn Cookies on.") }}</li>
                    </ol>
                </div>
                <div class="guide mt-8">
                    <h2>{{ $t("On Safari (iOs)") }}</h2>
                    <ol>
                        <li>{{ $t("Go to Settings, then scroll down and select “Safari”.") }}</li>
                        <li>{{ $t("Scroll down to “Privacy & Security”.") }}</li>
                        <li>{{ $t("Verify “Block All Cookies” is unticked, click to allow cookies.") }}</li>
                        <li>{{ $t("Clear the browser cache and reopen the browser.") }}</li>
                    </ol>
                </div>
            </Modal>
        </div>
    </div>
</template>

<script>
import LocalStorage from "@/lib/LocalStorage";
import Modal from "@/components/Modal";
import iconCookie from "@/assets/icons/icon-cookie.svg?inline";

export default {
    name: "LocalStorageNotSupported",
    components: {
        Modal,
        iconCookie
    },
    data() {
        return {
            isSupported: false
        };
    },
    mounted() {
        this.isSupported = LocalStorage.GetStorage() !== null;
    }
};
</script>
<style lang="scss">
@import "index";
</style>
